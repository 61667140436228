<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
        
        const items = [
            {
                key: 'USA_PICKLEBALL',
                title: 'USA Pickleball',
                src: '/assets/images/brands/usa-pickleball-logo-white.png'
            },
            {
                key: 'APP',
                title: 'The Association Of Pickleball Players',
                src: '/assets/images/brands/app-logo-white.png'
            },
            {
                key: 'DINK',
                title: 'The Dink',
                src: '/assets/images/brands/the-dink-logo-white.png'
            },
            {
                key: 'UTR_SPORT',
                title: 'UTR Sports',
                src: '/assets/images/brands/utr-sport-logo-white.png'
            },
        ]

        return { items };
    },
})
</script>


<template>
    <div class="brands-carousel-1">
        <Swiper
            :slides-per-view="2"
            :loop="true"
            :autoplay="{
                delay: 8000,
                disableOnInteraction: true
            }"
            :breakpoints="{
                400:{ slidesPerView:2 },
                600:{ slidesPerView:3 },
                900:{ slidesPerView:4, },
                1024:{ slidesPerView:5, },
            }"
        >
            <SwiperSlide v-for="item in items" :key="item.key">
                <div class="brand-logo">
                    <img class="img-fluid" :src="item.src" :alt="item.title" />
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>
